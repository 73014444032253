import { IonContent, IonIcon, IonPage, IonSpinner, useIonRouter } from "@ionic/react";
import { ImageVisualizer, InformativeModal, LoadingModal, ModalBackground, QuestionModal, SidebarMenu, SolidButton, TextArea, TextField } from "components";
import { useAppDispatch, useAppSelector, useForm } from "hooks";
import { convertTimestampToDate, createDocumentWithGeneratedId, getAddressErrorMessage, getCardCodeErrorMessage, getCardFNameErrorMessage, getCardNameErrorMessage, getContactPersonErrorMessage, getContactPhoneErrorMessage, getDocumentsByFilterArray, getDocumentById, getEmailErrorMessage, updateDocument, validateAddress, validateCardCode, validateCardFName, validateCardName, validateContactPerson, validateContactPhone, validateEmail, validateNitIvaCard, getNitIvaCardErrorMessage, validateNrcIvaCards, getNrcIvaCardsErrorMessage, uploadImageToFirebaseBucket } from "utils";
import { caretBack, caretDown, cloudUpload } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { getDepartmensList, getDistrictsList, getGyresList, getMunicipalitiesList } from "services";
import { setIvaCardsState } from "store/iva-cards";
import { IvaCardToUpload } from "interfaces";

const formData = {
    address: '',
    cardCode: '',
    cardFName: '',
    cardName: '',
    email: '',
    nrc: '',
    numberDocument: '',
    contactPhone: '',
    contactPerson: '',
}

const formValidations = {
    address: [validateAddress, getAddressErrorMessage],
    cardCode: [validateCardCode, getCardCodeErrorMessage],
    cardFName: [validateCardFName, getCardFNameErrorMessage],
    cardName: [validateCardName, getCardNameErrorMessage],
    email: [validateEmail, getEmailErrorMessage],
    nrc: [validateNrcIvaCards, getNrcIvaCardsErrorMessage],
    numberDocument: [validateNitIvaCard, getNitIvaCardErrorMessage],
    contactPhone: [validateContactPhone, getContactPhoneErrorMessage],
    contactPerson: [validateContactPerson, getContactPersonErrorMessage],
}

export const AddIvaCardPage = () => {
    const { uid, role } = useAppSelector(state => state.user);
    const { gyres, departments, municipalities, districts, businessCategories } = useAppSelector(state => state.ivaCards);

    const {
        cardCode, cardFName, cardName, numberDocument, nrc, economicActivity, address,
        departament, municipality, district, email, contactPerson, contactPhone, businessType,

        cardCodeValid, cardFNameValid, cardNameValid, numberDocumentValid, nrcValid,
        economicActivityValid, addressValid, departamentValid, municipalityValid, districtValid,
        emailValid, contactPersonValid, contactPhoneValid, businessTypeValid,

        isFormValid, onInputChange, onSelectFieldInputChange, setFormState
    } = useForm(formData, formValidations);

    const [loadingInformation, setLoadingInformation] = useState<boolean>(true);
    const [errorGettingInfo, setErrorGettingInfo] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    // estado para almacenar la información de los departamentos, municipios, distritos
    // giros y categorías de negocios
    const [selectedDepartment, setSelectedDepartment] = useState<any>({ codeDepartament: '', departament: '', showOptions: false });
    const [selectedMunicipality, setSelectedMunicipality] = useState<any>({ codeMunicipality: '', municipality: '', showOptions: false });
    const [selectedDistrict, setSelectedDistrict] = useState<any>({ codeDistrict: '', district: '', showOptions: false });
    const [selectedGyre, setSelectedGyre] = useState<any>({ codeActivity: '', economicActivity: '', showOptions: false });
    const [selectedCategory, setSelectedCategory] = useState<any>({ codeCategory: '', nameCategory: '', showOptions: false });

    const frontalImageInputRef = useRef(document.createElement("input"));
    const backImageInputRef = useRef(document.createElement("input"));

    const [frontalImageToUpload, setFrontalImageToUpload] = useState<IvaCardToUpload | null>(null);
    const [backImageToUpload, setBackImageToUpload] = useState<IvaCardToUpload | null>(null);

    // estado que sirve para almacenar el arreglo de imágenes que se muestran en 
    // el visor de imágenes
    const [imageToShow, setImageToShow] = useState<string[]>([]);
    const [showImageModal, setShowImageModal] = useState<boolean>(false);

    // estados que nos sirven para mostrar los modales de confirmación del usuario
    const [showCancellationConfirmationModal, setShowCancellationConfirmationModal] = useState<boolean>(false);
    const [showEditConfirmationModal, setShowEditConfirmationModal] = useState<boolean>(false);

    // estado que nos sirve para mostrar un modal de carga mientras se está guardando la información editada
    const [savingInformation, setSavingInformation] = useState<boolean>(false);
    const [informationSavedSuccessfully, setInformationSavedSuccessfully] = useState<boolean>(false);
    const [errorSavingInformation, setErrorSavingInformation] = useState<boolean>(false);

    const [departmentsList, setDepartmentsList] = useState<any[]>(departments);
    const [municipalitiesList, setMunicipalitiesList] = useState<any[]>(municipalities);
    const [districtsList, setDistrictsList] = useState<any[]>(districts);
    const [gyresList, setGyresList] = useState<any[]>(gyres);
    const [categoriesList, setCategoriesList] = useState<any[]>(businessCategories);

    const ionRouter = useIonRouter();
    const dispatch = useAppDispatch();

    useEffect(() => {
        getInformation();
    }, []);

    const getInformation = async () => {
        if (uid) {
            setLoadingInformation(true);

            // Validamos si la información de los departamentos, municipios, distritos y giros ya se encuentra almacenada
            if (gyres.length === 0 || departments.length === 0 || municipalities.length === 0 || districts.length === 0) {
                // recuperamos la información de las listas
                const gyresResp = await getGyresList(uid);

                setGyresList(gyresResp);

                const departmentsResp = await getDepartmensList(uid);

                setDepartmentsList(departmentsResp);

                const municipalitiesResp = await getMunicipalitiesList(uid);

                setMunicipalitiesList(municipalitiesResp);

                const districtsResp = await getDistrictsList(uid);

                setDistrictsList(districtsResp);

                let ivaCardsPayload = {
                    gyres: gyresResp,
                    departments: departmentsResp,
                    municipalities: municipalitiesResp,
                    districts: districtsResp,
                }

                dispatch(setIvaCardsState(ivaCardsPayload));
            }

            setLoadingInformation(false);
        }
    }

    const clickImageHandler = (image: string | null) => {
        if (image) {
            setShowImageModal(true);

            const imageArray = [image];

            setImageToShow(imageArray);
        }
    }

    // Función para guardar en local las imágenes que desea subir el usuario
    const changeIvaCardImage = (event: React.ChangeEvent<HTMLInputElement>, type: "frontal" | "back") => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];

            let [, extension] = file.name.split(".");

            let newIvaCardFile: IvaCardToUpload = {
                file,
                downloadUrl: URL.createObjectURL(file),
                extension
            }

            if (type === "frontal") {
                setFrontalImageToUpload(newIvaCardFile)
            } else {
                setBackImageToUpload(newIvaCardFile)
            }
        }
    }

    const showGyres = () => {
        let newSelectedGyreState = {
            ...selectedGyre,
            showOptions: !selectedGyre.showOptions,
        }

        setSelectedGyre(newSelectedGyreState);
    }

    const changeGyre = (code: string, description: string) => {
        let newSelectedGyreState = {
            codeActivity: code,
            economicActivity: description.toUpperCase(),
            showOptions: false,
        }

        setSelectedGyre(newSelectedGyreState);

        setGyresList(gyres);
    }

    const showDepartments = () => {
        let newSelectedDepartmentState = {
            ...selectedDepartment,
            showOptions: !selectedDepartment.showOptions,
        }

        setSelectedDepartment(newSelectedDepartmentState);
    }

    const changeDepartment = (code: string, description: string) => {
        let newSelectedDepartmentState = {
            codeDepartament: code,
            departament: description.toUpperCase(),
            showOptions: false,
        }

        setSelectedDepartment(newSelectedDepartmentState);

        setDepartmentsList(departments);
    }

    const showMunicipalities = () => {
        let newSelectedMunicipalityState = {
            ...selectedMunicipality,
            showOptions: !selectedMunicipality.showOptions,
        }

        setSelectedMunicipality(newSelectedMunicipalityState);
    }

    const changeMunicipality = (code: string, description: string) => {
        let newSelectedMunicipalityState = {
            codeMunicipality: code,
            municipality: description.toUpperCase(),
            showOptions: false,
        }

        setSelectedMunicipality(newSelectedMunicipalityState);

        setMunicipalitiesList(municipalities);
    }

    const showDistricts = () => {
        let newSelectedDistrictState = {
            ...selectedDistrict,
            showOptions: !selectedDistrict.showOptions,
        }

        setSelectedDistrict(newSelectedDistrictState);
    }

    const changeDistrict = (code: string, description: string) => {
        let newSelectedDistrictState = {
            codeDistrict: code,
            district: description.toUpperCase(),
            showOptions: false,
        }

        setSelectedDistrict(newSelectedDistrictState);

        setDistrictsList(districts);
    }

    const showCategories = () => {
        let newSelectedCategoryState = {
            ...selectedCategory,
            showOptions: !selectedCategory.showOptions,
        }

        setSelectedCategory(newSelectedCategoryState);
    }

    const changeCategory = (code: string, description: string) => {
        let newSelectedCategoryState = {
            codeCategory: code,
            nameCategory: description.toUpperCase(),
            showOptions: false,
        }

        setSelectedCategory(newSelectedCategoryState);

        setCategoriesList(businessCategories);
    }

    // Función para guardar la nueva información de la tarjeta de IVA que ha 
    // editado el usuario
    const saveNewInfo = async () => {
        setShowEditConfirmationModal(false);
        setSavingInformation(true);

        setErrorSavingInformation(false);

        let imageBackground = "";
        let imageFrontal = "";

        // verificamos si el usuario quiere modificar alguna de las fotografías de la tarjeta
        if (frontalImageToUpload) {
            const uploadImageResp = await uploadImageToFirebaseBucket(frontalImageToUpload.file, `vats/${cardCode}`, `${cardCode}-FrontalImage.${frontalImageToUpload.extension}`)

            if (uploadImageResp.ok) {
                imageFrontal = uploadImageResp.downloadURL
            }
        }

        if (backImageToUpload) {
            const uploadImageResp = await uploadImageToFirebaseBucket(backImageToUpload.file, `vats/${cardCode}`, `${cardCode}-BackImage.${backImageToUpload.extension}`)

            console.log(uploadImageResp);

            if (uploadImageResp.ok) {
                imageBackground = uploadImageResp.downloadURL
            }
        }

        // guardamos en la base de datos la información modificada
        // copiamos la información de la tarjeta de IVA tal cual se encuentra en la base de datos
        let newInfo: any = {
            address,
            cardCode,
            cardFName,
            cardName,
            codeActivity: selectedGyre.codeActivity,
            codeCategory: selectedCategory.codeCategory,
            codeDepartament: selectedDepartment.codeDepartament,
            codeDistrict: selectedDistrict.codeDistrict,
            codeMunicipality: selectedMunicipality.codeMunicipality,
            createdAt: new Date(),
            createdBy: uid,
            departament: selectedDepartment.departament,
            district: selectedDistrict.district,
            economicActivity: selectedGyre.economicActivity,
            email,
            imageBackground,
            imageFrontal,
            municipality: selectedMunicipality.municipality,
            nameCategory: selectedCategory.nameCategory,
            nrc,
            numberDocument,
            phoneNumber: contactPhone,
            providedBy: contactPerson,
            test: false,
        };

        const createResp = await createDocumentWithGeneratedId('vat', newInfo);

        if (createResp.status === 201) {
            setInformationSavedSuccessfully(true);
        } else {
            setErrorSavingInformation(true);
        }

        setSavingInformation(false);
    }

    const acceptSuccessfulMessageHandler = () => {
        setInformationSavedSuccessfully(false);
        ionRouter.push("/ivaCards", "back", "pop");
    }

    const cancelEditionInfoHandler = () => {
        // regresamos el formulario a su estado original
        setShowCancellationConfirmationModal(true);
    }

    const searchGyre = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        if (value.length > 0) {
            let newGyreList = gyres.filter((item: any) => item.Descr.toUpperCase().includes(value.toUpperCase()));

            setGyresList(newGyreList);
        } else {
            setGyresList(gyres);
        }
    }

    const searchDepartment = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        if (value.length > 0) {
            let newList = departments.filter((item: any) => item.Descr.toUpperCase().includes(value.toUpperCase()));

            setDepartmentsList(newList);
        } else {
            setDepartmentsList(departments);
        }
    }

    const searchMunicipality = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        if (value.length > 0) {
            let newList = municipalities.filter((item: any) => item.Descr.toUpperCase().includes(value.toUpperCase()));

            setMunicipalitiesList(newList);
        } else {
            setMunicipalitiesList(municipalities);
        }
    }

    const searchDistricts = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        if (value.length > 0) {
            let newList = districts.filter((item: any) => item.Descr.toUpperCase().includes(value.toUpperCase()));

            setDistrictsList(newList);
        } else {
            setDistrictsList(districts);
        }
    }

    const searchCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        if (value.length > 0) {
            let newList = businessCategories.filter((item: any) => item.name.toUpperCase().includes(value.toUpperCase()));

            setCategoriesList(newList);
        } else {
            setCategoriesList(businessCategories);
        }
    }

    return (
        <IonPage>
            <IonContent>
                <div className="w-full h-screen grid grid-cols-12">
                    <div className="col-span-2 border-r-2 border-solid border-light">
                        <SidebarMenu pageName="iva_cards" />
                    </div>

                    <div className="w-full h-screen flex flex-col col-span-10">
                        <div className="w-full flex justify-between items-center border-b-2 border-solid border-tertiary p-4">
                            <div className={`flex items-center gap-2`}>
                                <IonIcon onClick={() => ionRouter.push("/ivaCards", "back", "push")} icon={caretBack} className="text-primary cursor-pointer text-[20px]" />


                                <p className="font-bold">Agregar tarjeta de I.V.A.</p>
                            </div>

                            <div className="flex items-center gap-4">
                                <button
                                    onClick={cancelEditionInfoHandler}
                                    className="bg-secondary text-white font-bold px-4 py-1 rounded-md"
                                >
                                    CANCELAR
                                </button>

                                <button
                                    onClick={() => setShowEditConfirmationModal(true)}
                                    disabled={!isFormValid || selectedCategory.codeCategory.length === 0 || selectedDepartment.codeDepartament.length === 0 || selectedDistrict.codeDistrict.length === 0 || selectedGyre.codeActivity.length === 0 || selectedMunicipality.codeMunicipality.length === 0 || frontalImageToUpload === null || backImageToUpload === null}
                                    className={`bg-success text-white font-bold px-4 py-1 rounded-md disabled:opacity-40`}
                                >
                                    GUARDAR
                                </button>
                            </div>
                        </div>

                        <div id="main-section" className="w-full h-screen overflow-y-scroll grid grid-cols-10">
                            {
                                loadingInformation &&
                                <div className="w-full flex flex-col justify-center items-center col-span-10">
                                    <IonSpinner name="crescent" color="primary" className="w-[50px] h-[50px]" />
                                </div>
                            }

                            {
                                !loadingInformation && errorGettingInfo &&
                                <div className="w-full flex flex-col justify-center items-center p-4 col-span-10">
                                    <div className="w-3/5 flex flex-col items-center gap-4">
                                        <p className="font-semibold text-primary text-center">{errorMessage}</p>

                                        <div className="w-3/5">
                                            <SolidButton
                                                text="Recargar"
                                                backgroundColor="primary"
                                                disabled={false}
                                                onClickHandler={getInformation}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                !loadingInformation && !errorGettingInfo &&
                                <div className="w-full flex flex-col items-center col-span-10">
                                    <div className="w-full p-8 grid grid-cols-2 gap-4">
                                        <div className="w-full flex flex-col pb-16 text-mid order-1 gap-6">
                                            <div className="w-1/2">
                                                <TextField
                                                    name="cardCode"
                                                    label="CÓDIGO DE CLIENTE"
                                                    value={cardCode}
                                                    inputMode="text"
                                                    changeHandler={onInputChange}
                                                    isFieldValid={cardCodeValid}
                                                    errorMessage={cardCodeValid}
                                                />
                                            </div>

                                            <div className="w-1/2">
                                                <TextField
                                                    name="cardFName"
                                                    label="NOMBRE COMERCIAL"
                                                    value={cardFName}
                                                    inputMode="text"
                                                    changeHandler={onInputChange}
                                                    isFieldValid={cardFNameValid}
                                                    errorMessage={cardFNameValid}
                                                    required={true}
                                                />
                                            </div>

                                            <div className="w-1/2">
                                                <TextField
                                                    name="cardName"
                                                    label="NOMBRE DEL CONTRIBUYENTE"
                                                    value={cardName}
                                                    inputMode="text"
                                                    changeHandler={onInputChange}
                                                    isFieldValid={cardNameValid}
                                                    errorMessage={cardNameValid}
                                                    required={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="w-full grid pb-32 text-mid order-2 gap-6">
                                            <div className="w-1/2">
                                                <TextField
                                                    name="numberDocument"
                                                    label="NÚMERO DE IDENTIFICACIÓN TRIBUTARIA (N.I.T.)"
                                                    value={numberDocument}
                                                    inputMode="numeric"
                                                    changeHandler={onInputChange}
                                                    isFieldValid={numberDocumentValid}
                                                    errorMessage={numberDocumentValid}
                                                    required={true}
                                                    maxLength={17}
                                                />
                                            </div>

                                            <div className="w-1/2">
                                                <TextField
                                                    name="nrc"
                                                    label="NÚMERO DE REGISTRO (N.R.C.)"
                                                    value={nrc}
                                                    inputMode="numeric"
                                                    changeHandler={onInputChange}
                                                    isFieldValid={nrcValid}
                                                    errorMessage={nrcValid}
                                                    required={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="w-full grid pb-16 text-mid order-3 gap-6">
                                            <div className="flex flex-col gap-1">
                                                <p className="font-semibold text-medium">GIRO O ACTIVIDAD ECONÓMICA</p>

                                                <div className="w-1/2 flex flex-col">
                                                    <div
                                                        className={`flex justify-between items-center border pl-2 rounded-md ${selectedGyre && selectedGyre.hasOwnProperty("economicActivity") && selectedGyre.economicActivity.length > 0 ? 'border-success' : 'border-danger'}`}
                                                    >
                                                        <p className="py-2">{selectedGyre?.economicActivity.length > 0 ? selectedGyre?.economicActivity : 'Seleccione un giro'}</p>

                                                        <div
                                                            onClick={showGyres}
                                                            className={`h-full flex items-center border-l px-4 cursor-pointer ${selectedGyre && selectedGyre.hasOwnProperty("economicActivity") && selectedGyre.economicActivity.length > 0 ? 'border-success' : 'border-danger'}`}>
                                                            <IonIcon
                                                                icon={caretDown}
                                                                className="text-[20px]"
                                                            />
                                                        </div>

                                                    </div>

                                                    {
                                                        selectedGyre?.showOptions &&
                                                        <div className="flex flex-col border-l border-b border-r border-tertiary rounded-b-md max-h-[250px]">
                                                            <div className="p-4 border-b border-tertiary">
                                                                <input
                                                                    type="text"
                                                                    id="gyres-filter"
                                                                    name="gyres-filter"
                                                                    placeholder="Escribe aquí para buscar"
                                                                    className="w-full border border-solid border-primary p-2 rounded-md"
                                                                    onChange={searchGyre}
                                                                />
                                                            </div>

                                                            <div className="flex flex-col overflow-y-auto">
                                                                {
                                                                    gyresList.map((gyreItem: any) => (
                                                                        <div
                                                                            key={gyreItem.FldValue}
                                                                            onClick={() => changeGyre(gyreItem.FldValue, gyreItem.Descr)}
                                                                            className="flex items-center gap-2 px-4 py-2 border-b border-tertiary last:border-b-0 cursor-pointer hover:bg-light"
                                                                        >
                                                                            <p>{gyreItem.Descr}</p>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>

                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                            <div className="w-1/2">
                                                <TextArea
                                                    name="address"
                                                    label="DIRECCIÓN"
                                                    value={address}
                                                    inputMode="text"
                                                    changeHandler={onInputChange}
                                                    isFieldValid={addressValid}
                                                    errorMessage={addressValid}
                                                    required={true}
                                                />
                                            </div>

                                            <div className="flex flex-col gap-1">
                                                <p className="font-semibold text-medium">DEPARTAMENTO</p>

                                                <div className="w-1/2 flex flex-col">
                                                    <div
                                                        className={`flex justify-between items-center border pl-2 rounded-md ${selectedDepartment && selectedDepartment.hasOwnProperty("departament") && selectedDepartment.departament.length > 0 ? 'border-success' : 'border-danger'}`}
                                                    >
                                                        <p className="py-2">{selectedDepartment?.departament.length > 0 ? selectedDepartment?.departament : 'Seleccione un departamento'}</p>

                                                        <div
                                                            onClick={showDepartments}
                                                            className={`h-full flex items-center border-l px-4 cursor-pointer ${selectedDepartment && selectedDepartment.hasOwnProperty("departament") && selectedDepartment.departament.length > 0 ? 'border-success' : 'border-danger'}`}>
                                                            <IonIcon
                                                                icon={caretDown}
                                                                className="text-[20px]"
                                                            />
                                                        </div>

                                                    </div>

                                                    {
                                                        selectedDepartment?.showOptions &&
                                                        <div className="flex flex-col border-l border-b border-r border-tertiary rounded-b-md max-h-[250px]">
                                                            <div className="p-4 border-b border-tertiary">
                                                                <input
                                                                    type="text"
                                                                    id="deparments-filter"
                                                                    name="deparments-filter"
                                                                    placeholder="Escribe aquí para buscar"
                                                                    className="w-full border border-solid border-primary p-2 rounded-md"
                                                                    onChange={searchDepartment}
                                                                />
                                                            </div>

                                                            <div className="flex flex-col overflow-y-auto">
                                                                {
                                                                    departmentsList.map((departmentItem: any) => (
                                                                        <div
                                                                            key={departmentItem.FldValue}
                                                                            onClick={() => changeDepartment(departmentItem.FldValue, departmentItem.Descr)}
                                                                            className="flex items-center gap-2 px-4 py-2 border-b border-tertiary last:border-b-0 cursor-pointer hover:bg-light"
                                                                        >
                                                                            <p>{departmentItem.Descr}</p>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                            <div className="flex flex-col gap-1">
                                                <p className="font-semibold text-medium">MUNICIPIO</p>

                                                <div className="w-1/2 flex flex-col">
                                                    <div
                                                        className={`flex justify-between items-center border pl-2 rounded-md ${selectedMunicipality && selectedMunicipality.hasOwnProperty("municipality") && selectedMunicipality.municipality.length > 0 ? 'border-success' : 'border-danger'}`}
                                                    >
                                                        <p className="py-2">{selectedMunicipality?.municipality?.length > 0 ? selectedMunicipality?.municipality : 'Seleccione un municipio'}</p>

                                                        <div
                                                            onClick={showMunicipalities}
                                                            className={`h-full flex items-center border-l px-4 cursor-pointer ${selectedMunicipality && selectedMunicipality.hasOwnProperty("municipality") && selectedMunicipality.municipality.length > 0 ? 'border-success' : 'border-danger'}`}>
                                                            <IonIcon
                                                                icon={caretDown}
                                                                className="text-[20px]"
                                                            />
                                                        </div>

                                                    </div>

                                                    {
                                                        selectedMunicipality?.showOptions &&
                                                        <div className="flex flex-col border-l border-b border-r border-tertiary rounded-b-md max-h-[250px]">
                                                            <div className="p-4 border-b border-tertiary">
                                                                <input
                                                                    type="text"
                                                                    id="municipalities-filter"
                                                                    name="municipalities-filter"
                                                                    placeholder="Escribe aquí para buscar"
                                                                    className="w-full border border-solid border-primary p-2 rounded-md"
                                                                    onChange={searchMunicipality}
                                                                />
                                                            </div>

                                                            <div className="flex flex-col overflow-y-auto">
                                                                {
                                                                    municipalitiesList.map((municipalityItem: any) => (
                                                                        <div
                                                                            key={municipalityItem.FldValue}
                                                                            onClick={() => changeMunicipality(municipalityItem.FldValue, municipalityItem.Descr)}
                                                                            className="flex items-center gap-2 px-4 py-2 border-b border-tertiary last:border-b-0 cursor-pointer hover:bg-light"
                                                                        >
                                                                            <p>{municipalityItem.Descr}</p>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                            <div className="flex flex-col gap-1">
                                                <p className="font-semibold text-medium">DISTRITO</p>

                                                <div className="w-1/2 flex flex-col">
                                                    <div
                                                        className={`flex justify-between items-center border pl-2 rounded-md ${selectedDistrict && selectedDistrict.hasOwnProperty("district") && selectedDistrict.district.length > 0 ? 'border-success' : 'border-danger'}`}
                                                    >
                                                        <p className="py-2">{selectedDistrict?.district?.length > 0 ? selectedDistrict?.district : 'Seleccione un distrito'}</p>

                                                        <div
                                                            onClick={showDistricts}
                                                            className={`h-full flex items-center border-l px-4 cursor-pointer ${selectedDistrict && selectedDistrict.hasOwnProperty("district") && selectedDistrict.district.length > 0 ? 'border-success' : 'border-danger'}`}>
                                                            <IonIcon
                                                                icon={caretDown}
                                                                className="text-[20px]"
                                                            />
                                                        </div>

                                                    </div>

                                                    {
                                                        selectedDistrict?.showOptions &&
                                                        <div className="flex flex-col border-l border-b border-r border-tertiary rounded-b-md max-h-[250px]">
                                                            <div className="p-4 border-b border-tertiary">
                                                                <input
                                                                    type="text"
                                                                    id="districts-filter"
                                                                    name="districts-filter"
                                                                    placeholder="Escribe aquí para buscar"
                                                                    className="w-full border border-solid border-primary p-2 rounded-md"
                                                                    onChange={searchDistricts}
                                                                />
                                                            </div>

                                                            <div className="flex flex-col overflow-y-auto">
                                                                {
                                                                    districtsList.map((districtItem: any) => (
                                                                        <div
                                                                            key={districtItem.FldValue}
                                                                            onClick={() => changeDistrict(districtItem.FldValue, districtItem.Descr)}
                                                                            className="flex items-center gap-2 px-4 py-2 border-b border-tertiary last:border-b-0 cursor-pointer hover:bg-light"
                                                                        >
                                                                            <p>{districtItem.Descr}</p>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-full grid pb-16 text-mid order-4 gap-6">
                                            <div className="w-1/2">
                                                <TextField
                                                    name="email"
                                                    label="CORREO"
                                                    value={email}
                                                    inputMode="text"
                                                    changeHandler={onInputChange}
                                                    isFieldValid={emailValid}
                                                    errorMessage={emailValid}
                                                    required={true}
                                                />
                                            </div>

                                            <div className="w-1/2">
                                                <TextArea
                                                    name="contactPerson"
                                                    label="PERSONA DE CONTACTO"
                                                    value={contactPerson}
                                                    inputMode="text"
                                                    changeHandler={onInputChange}
                                                    isFieldValid={contactPersonValid}
                                                    errorMessage={contactPersonValid}
                                                    required={true}
                                                />
                                            </div>

                                            <div className="w-1/2">
                                                <TextField
                                                    name="contactPhone"
                                                    label="TELÉFONO DE CONTACTO"
                                                    value={contactPhone}
                                                    inputMode="numeric"
                                                    maxLength={8}
                                                    changeHandler={onInputChange}
                                                    isFieldValid={contactPhoneValid}
                                                    errorMessage={contactPhoneValid}
                                                    required={true}
                                                />
                                            </div>

                                            <div className="flex flex-col gap-1">
                                                <p className="font-semibold text-medium">TIPO DE EMPRESA</p>

                                                <div className="w-1/2 flex flex-col">
                                                    <div
                                                        className={`flex justify-between items-center border pl-2 rounded-md ${selectedCategory && selectedCategory.hasOwnProperty("nameCategory") && selectedCategory.nameCategory.length > 0 ? 'border-success' : 'border-danger'}`}
                                                    >
                                                        <p className="py-2">{selectedCategory?.nameCategory?.length > 0 ? selectedCategory?.nameCategory : 'Selecciona una categoría'}</p>

                                                        <div
                                                            onClick={showCategories}
                                                            className={`h-full flex items-center border-l px-4 cursor-pointer ${selectedCategory && selectedCategory.hasOwnProperty("nameCategory") && selectedCategory.nameCategory.length > 0 ? 'border-success' : 'border-danger'}`}>
                                                            <IonIcon
                                                                icon={caretDown}
                                                                className="text-[20px]"
                                                            />
                                                        </div>

                                                    </div>

                                                    {
                                                        selectedCategory?.showOptions &&
                                                        <div className="flex flex-col border-l border-b border-r border-tertiary rounded-b-md max-h-[250px]">
                                                            <div className="p-4 border-b border-tertiary">
                                                                <input
                                                                    type="text"
                                                                    id="categories-filter"
                                                                    name="categories-filter"
                                                                    placeholder="Escribe aquí para buscar"
                                                                    className="w-full border border-solid border-primary p-2 rounded-md"
                                                                    onChange={searchCategory}
                                                                />
                                                            </div>

                                                            <div className="flex flex-col overflow-y-auto">
                                                                {
                                                                    categoriesList.map((item: any) => (
                                                                        <div
                                                                            key={item.code}
                                                                            onClick={() => changeCategory(item.code, item.name)}
                                                                            className="flex items-center gap-2 px-4 py-2 border-b border-tertiary last:border-b-0 cursor-pointer hover:bg-light"
                                                                        >
                                                                            <p>{item.name}</p>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-full order-5"></div>

                                        <div className="w-full order-6 text-mid flex flex-col items-start gap-6">
                                            <div className="grid gap-2">
                                                <p className="font-semibold text-medium">FOTOGRAFÍA FRONTAL DE LA TARJETA</p>

                                                <div className="flex items-center gap-8">
                                                    {
                                                        frontalImageToUpload && frontalImageToUpload.hasOwnProperty("downloadUrl") &&
                                                        <div
                                                            onClick={() => clickImageHandler(frontalImageToUpload?.downloadUrl)}
                                                            className="w-[256px] min-[425]:w-[300px] max-h-48 bg-light flex justify-center items-center rounded-md iva-card-border-dashed"
                                                        >
                                                            <img
                                                                src={frontalImageToUpload?.downloadUrl}
                                                                alt="frontal-image"
                                                                className="w-[200px] max-h-48 object-contain cursor-pointer"
                                                            />
                                                        </div>
                                                    }

                                                    <>
                                                        <input
                                                            type='file'
                                                            accept='image/*'
                                                            ref={frontalImageInputRef}
                                                            onChange={(event) => changeIvaCardImage(event, "frontal")}
                                                            style={{ display: 'none' }}
                                                        />

                                                        <button
                                                            onClick={() => frontalImageInputRef.current.click()}
                                                            className="font-bold px-4 py-2 bg-tertiary rounded-md flex items-center gap-2"
                                                        >
                                                            <IonIcon icon={cloudUpload} className="text-[20px]" />
                                                            Subir fotografía
                                                        </button>
                                                    </>
                                                </div>
                                            </div>

                                            <div className="grid gap-2">
                                                <p className="font-semibold text-medium">FOTOGRAFÍA POSTERIOR DE LA TARJETA</p>

                                                <div className="flex items-center gap-8">
                                                    {
                                                        backImageToUpload && backImageToUpload.hasOwnProperty("downloadUrl") &&
                                                        <div
                                                            onClick={() => clickImageHandler(backImageToUpload?.downloadUrl)}
                                                            className="w-[256px] min-[425]:w-[300px] max-h-48 bg-light flex justify-center items-center rounded-md iva-card-border-dashed"
                                                        >
                                                            <img
                                                                src={backImageToUpload?.downloadUrl}
                                                                alt="rear-image"
                                                                className="w-[200px] max-h-48 object-contain cursor-pointer"
                                                            />
                                                        </div>
                                                    }

                                                    <>
                                                        <input
                                                            type='file'
                                                            accept='image/*'
                                                            ref={backImageInputRef}
                                                            onChange={(event) => changeIvaCardImage(event, "back")}
                                                            style={{ display: 'none' }}
                                                        />

                                                        <button
                                                            onClick={() => backImageInputRef.current.click()}
                                                            className="font-bold px-4 py-2 bg-tertiary rounded-md flex items-center gap-2"
                                                        >
                                                            <IonIcon icon={cloudUpload} className="text-[20px]" />
                                                            Subir fotografía
                                                        </button>
                                                    </>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </IonContent>

            {
                (showImageModal || savingInformation || showCancellationConfirmationModal || showEditConfirmationModal || errorSavingInformation || informationSavedSuccessfully) &&
                <ModalBackground />
            }

            {
                showImageModal &&
                <ImageVisualizer imageArray={imageToShow} imageAlt="document" clickOutsideHandler={() => setShowImageModal(false)} />
            }

            {
                savingInformation &&
                <LoadingModal whiteBackground={true} text="Guardando información..." />
            }

            {
                showEditConfirmationModal &&
                <QuestionModal
                    question={`<p><strong>¿Está seguro que desea guardar la información actual?</strong></p>`}
                    cancelClickHandler={() => setShowEditConfirmationModal(false)}
                    acceptClickHandler={saveNewInfo}
                />
            }

            {
                showCancellationConfirmationModal &&
                <QuestionModal
                    question={`<p><strong>¿Está seguro que desea salir de la pantalla actual?</strong> Se perderán todas las modificaciones hechas a la información que no hayan sido guardadas</p>`}
                    cancelClickHandler={() => setShowCancellationConfirmationModal(false)}
                    acceptClickHandler={() => { setShowCancellationConfirmationModal(false); ionRouter.push("/ivaCards", "back", "push") }}
                />
            }

            {
                errorSavingInformation &&
                <InformativeModal
                    type="warning"
                    title="Advertencia"
                    text="Ha ocurrido un error al guardar la información. Intenta realizar nuevamente el proceso y si el error persiste comúnicate con el equipo de soporte técnico"
                    acceptClickHandler={() => setErrorSavingInformation(false)}
                />
            }

            {
                informationSavedSuccessfully &&
                <InformativeModal
                    type="success"
                    title="La tarjeta de IVA ha sido guardada exitosamente"
                    acceptClickHandler={acceptSuccessfulMessageHandler}
                />
            }
        </IonPage>
    )
}