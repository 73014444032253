import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { formatDateToISODateString } from "utils";

interface IvaCardsState {
    gyres: any[],
    departments: any[],
    municipalities: any[],
    districts: any[],
    businessCategories: any[],
}

const initialState: IvaCardsState = {
    gyres: [],
    departments: [],
    municipalities: [],
    districts: [],
    businessCategories: [
        { code: "1", name: "GRANDE", label:"grande" },
        { code: "2", name: "MEDIANOS", label:"medianos" },
        { code: "3", name: "PEQUEÑOS", label:"pequeños" },
        { code: "4", name: "OTROS", label:"otros" }
    ]
}

export const ivaCardsSlice = createSlice({
    name: 'iva-cards',
    initialState,
    reducers: {
        setIvaCardsState: (state, action: PayloadAction<{ gyres: any[], departments: any[], municipalities: any[], districts: any[] }>) => {
            state.gyres = action.payload.gyres;
            state.departments = action.payload.departments;
            state.municipalities = action.payload.municipalities;
            state.districts = action.payload.districts;
        },
        resetIvaCardsState: (state) => {
            state.gyres = [];
            state.departments = [];
            state.municipalities = [];
            state.districts = [];
        }
    },
})

// Action creators are generated for each case reducer function
export const 
{ 
    setIvaCardsState,
    resetIvaCardsState,
} = ivaCardsSlice.actions