import axios from "axios";

export async function getClientsService(uid: string, vendorCode: string) {
    let serviceResponse: any = null;
    
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/sapclientlist/get/pricelist/seller`,
            {
                token: uid,
                data: {
                    "userId_canal": vendorCode
                }
            },
            {
                timeout: 30000,
            }
        );
        
        if (res.data.data && res.data.data.length > 0) {
            serviceResponse = res.data.data;
        }
    } catch (error) {
        return null;
    }

    return serviceResponse;
}

export async function getClientInformationService(uid: string, clientCode: string) {
    let serviceResponse: any = null;
    
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/sapclientlist/get/pricelist/byidclient`,
            {
                token: uid,
                data: {
                    "validateAlls": true,
                    "cardCode": clientCode
                }
            },
            {
                timeout: 30000,
            }
        );
        
        if (res.data.data && res.data.data.length > 0) {
            serviceResponse = res.data.data;
        }
    } catch (error) {
        return null;
    }

    return serviceResponse;
}

export async function getGyresList(uid: string) {
    let serviceResponse: any = null;
    
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_SERVICE_URL}/sapclientlist/get/turn`,
            {
                headers: {
                    'Content-Type': 'application/json', 
                    authorization: "UserToken " + uid,
                },
                timeout: 10000,
            }
        );
        
        if (res.data.data && res.data.data.length > 0) {
            serviceResponse = res.data.data;
        }
    } catch (error) {
        return null;
    }

    return serviceResponse;
}

export async function getDepartmensList(uid: string) {
    let serviceResponse: any = null;
    
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_SERVICE_URL}/sapclientlist/get/departments`,
            {
                headers: {
                    'Content-Type': 'application/json', 
                    authorization: "UserToken " + uid,
                },
                timeout: 10000,
            }
        );
        
        if (res.data.data && res.data.data.length > 0) {
            serviceResponse = res.data.data;
        }
    } catch (error) {
        return null;
    }

    return serviceResponse;
}

export async function getMunicipalitiesList(uid: string) {
    let serviceResponse: any = null;
    
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_SERVICE_URL}/sapclientlist/get/municipalities`,
            {
                headers: {
                    'Content-Type': 'application/json', 
                    authorization: "UserToken " + uid,
                },
                timeout: 10000,
            }
        );
        
        if (res.data.data && res.data.data.length > 0) {
            serviceResponse = res.data.data;
        }
    } catch (error) {
        return null;
    }

    return serviceResponse;
}

export async function getDistrictsList(uid: string) {
    let serviceResponse: any = null;
    
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_SERVICE_URL}/sapclientlist/get/districts`,
            {
                headers: {
                    'Content-Type': 'application/json', 
                    authorization: "UserToken " + uid,
                },
                timeout: 10000,
            }
        );
        
        if (res.data.data && res.data.data.length > 0) {
            serviceResponse = res.data.data;
        }
    } catch (error) {
        return null;
    }

    return serviceResponse;
}