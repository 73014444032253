import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './auth';
import { userSlice } from './user';
import { settingsSlice } from './settings';
import { sellersDashboardSlice } from './sellers-dashboard';
import { ivaCardsSlice } from './iva-cards';

export const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        ivaCards: ivaCardsSlice.reducer,
        sellersDashboard: sellersDashboardSlice.reducer,
        user: userSlice.reducer,
        settings: settingsSlice.reducer,
    },
  })
  
  // Infer the `RootState` and `AppDispatch` types from the store itself
  export type RootState = ReturnType<typeof store.getState>
  // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
  export type AppDispatch = typeof store.dispatch